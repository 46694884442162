import axios from 'axios'
import store from '@/store'
import qs from 'qs'

// TODO: Update usages of this AxiosInstance to instead use 'apiV3AxiosClient' in src/api/client.js
const axiosClient = axios.create({
  baseURL: '/api/v3/',

  transformRequest:
    [
      (data, headers) => {
        Object.assign(headers, {
          Authorization: `Bearer ${store.getters['session/getToken']}`,
          'X-Admin-Impersonation': store.getters['session/isAdminImpersonatingClient'] ? 'true' : 'false',
        })
        return data
      },
      ...axios.defaults.transformRequest,
    ],
})

export const paramsSerializer = params => qs.stringify(params,
  {
    arrayFormat: 'brackets',
    encode: false,
  })

export default axiosClient
